/* eslint-disable camelcase */
import BigNumber from '@exchange/helpers/bignumber';

export interface BEFeeTier {
  volume: string;
  fee_group_id: string;
  maker_fee_spot: string;
  taker_fee_spot: string;
  maker_fee_perps: string;
  taker_fee_perps: string;
}

interface BEFeeGroupBase {
  account_id: string;
  active_fee_tier: BEFeeTier;
  collect_fees_in_best: boolean;
  fee_discount_rate: string;
  fee_group_id: string;
  fee_tiers: Array<BEFeeTier>;
  minimum_price_value: string;
  user_is_blocked?: boolean;
}

export type BEFeeGroup = BEFeeGroupBase & {
  running_volume: string;
  running_volume_currency: string;
};

export enum FeeType {
  maker = 'maker',
  taker = 'taker',
}

export interface Fees {
  makerFeeSpot: number;
  takerFeeSpot: number;
  makerFeePerps: number;
  takerFeePerps: number;
}

export class FeeTier {
  public volume!: number;

  public originalFees!: Fees;

  public reducedFees!: Fees;

  constructor(
    fields: BEFeeTier,
    public discountRate: number,
  ) {
    const makerFeeSpot = Number(fields.maker_fee_spot);
    const takerFeeSpot = Number(fields.taker_fee_spot);
    const makerFeePerps = Number(fields.maker_fee_perps);
    const takerFeePerps = Number(fields.taker_fee_perps);

    this.reducedFees = {
      makerFeeSpot: this.calculateFee(makerFeeSpot),
      takerFeeSpot: this.calculateFee(takerFeeSpot),
      makerFeePerps: this.calculateFee(makerFeePerps),
      takerFeePerps: this.calculateFee(takerFeePerps),
    };
    this.volume = Number(fields.volume);
    this.originalFees = {
      makerFeeSpot,
      takerFeeSpot,
      makerFeePerps,
      takerFeePerps,
    };
  }

  protected calculateFee(fee: number) {
    return new BigNumber(fee).minus(new BigNumber(fee).times(new BigNumber(this.discountRate).div(100))).toNumber();
  }
}

export class FeeGroup {
  public id!: string;

  public feeTiers!: Array<FeeTier>;

  public reducedFeeTiers!: Array<FeeTier>;

  public feeDiscountRate!: number;

  constructor(fields: BEFeeGroup) {
    this.id = fields.fee_group_id;
    const discount = Number(fields.fee_discount_rate);

    this.feeTiers = fields.fee_tiers.map((tier) => new FeeTier(tier, discount));
    this.feeDiscountRate = discount;
  }
}
