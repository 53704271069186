import orderBy from 'lodash/orderBy';
import { computed, ref } from 'vue';

import PublicRest from '@exchange/libs/rest-api/public-api';
import { retryService } from '@exchange/libs/utils/retry/src';
import { logger } from '@exchange/libs/utils/simple-logger/src';

import { FeeTier, FeeGroup, BEFeeGroup } from './fee-tiers-model';

class Fees {
  public publicFees = ref<FeeGroup>();

  public accountFees = ref<FeeGroup>();

  public accountTradingVolume = ref<number>(0);

  public feeCurrency = ref<string>();

  public accountFeeDiscountRate = computed(() => this.accountFees.value?.feeDiscountRate || 0);

  public publicCurrentFeeLevel = computed(() => {
    if (!this.publicFees.value) {
      return undefined;
    }

    const [currentLevel] = orderBy(this.publicFees.value.feeTiers, ['takerFee'], ['desc']) as Array<FeeTier>;

    return currentLevel;
  });

  public accountCurrentFeeLevel = computed(() => {
    if (!this.accountFees.value) {
      return undefined;
    }

    const currentLevel = [...this.accountFees.value.feeTiers].reverse().find((tier) => tier.volume <= this.accountTradingVolume.value);

    return currentLevel;
  });

  public accountCurrentFee = computed(() => {
    if (this.accountCurrentFeeLevel.value === undefined) {
      return undefined;
    }

    return this.accountCurrentFeeLevel.value.originalFees;
  });

  public accountCurrentFeeLevelIndex = computed(() => {
    if (!this.accountFees.value) {
      return undefined;
    }

    const index = this.accountFees.value.feeTiers.findIndex((tier) => tier.volume === this.accountCurrentFeeLevel.value?.volume);

    return index === -1 ? undefined : index + 1;
  });

  public setAccountFees = (v: FeeGroup) => {
    this.accountFees.value = v;
  };

  public fetchAccountFees = async () => {
    try {
      const feeGroup = (await PublicRest.Account.Fees.get()) as BEFeeGroup;

      this.setAccountFees(new FeeGroup(feeGroup));

      if ('running_volume_currency' in feeGroup) {
        this.accountTradingVolume.value = Number(feeGroup.running_volume);
        this.feeCurrency.value = feeGroup.running_volume_currency;
      }

      return Boolean(feeGroup.user_is_blocked);
    } catch (error) {
      logger.warn('Fetching account fees failed; retrying later', error);
      await retryService.waitForNextRetryTick();
      return this.fetchAccountFees();
    }
  };

  public fetchPublicFees = async () => {
    try {
      const [feeGroup] = await PublicRest.Fees.get();

      this.publicFees.value = new FeeGroup(feeGroup);
    } catch (error) {
      logger.warn('Fetching public fees failed; retrying later', error);
      await retryService.waitForNextRetryTick();
      await this.fetchPublicFees();
    }
  };
}

const feesService = new Fees();

export default feesService;
